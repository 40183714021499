@use 'src/styles/color';

.container {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
}

.imageWrapper {
  margin-right: 6px;
  width: 44px;
  height: 44px;
  background-color: rgba(color.$white, 0.1);
  flex-shrink: 0;
  border-radius: 2px;
  overflow: hidden;
  fill: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  margin-right: 6px;
  color: rgba(color.$white, 0.7);
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 1.2;
  white-space: nowrap;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  margin-left: auto;
  fill: rgba(255, 255, 255, 0.7);
  border: solid 2px transparent;
}
