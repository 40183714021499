.container {
  position: relative;
  padding: 12px 11px 5px;
}

.scrubber {
  margin-bottom: 12px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  flex-shrink: 0;
  padding: 0;
  border-radius: 100%;
  border-color: transparent;
  background: transparent;
  cursor: pointer;
  fill: #ffffff;
}

.playButton {
  margin: 0 60px;
  transition: transform 0.2s ease-out;
  &:hover {
    transform: scale(1.1, 1.1);
  }
  &:active {
    transition: transform 0.1s ease-out;
    transform: scale(0.95, 0.95);
  }
}

.skipButton {
  transition: opacity 0.2s ease-out;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }
  &:active {
    transition: opacity 0.1s ease-out;
    opacity: 0.75;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  animation: spin 1s linear infinite;
}
