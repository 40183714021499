@use 'src/styles/breakpoint';
@use 'src/styles/zIndex';
@use './components/Mini/variables' as mini;

.container {
  // NOTICE: Prevent user from selecting elements/text - This is to mimic a more app like behaviour on the player.
  user-select: none;
}

.miniWrapper {
  z-index: zIndex.$player;
  position: fixed;
  bottom: 0;
  width: 100%;
  @media (min-width: breakpoint.$md) {
    right: 16px;
    bottom: 16px;
    width: 375px;
  }
  transition: transform 0.2s cubic-bezier(0, 0, 0.1, 1);
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.3));
  visibility: visible;
  @media (min-width: breakpoint.$md) {
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
  }
}

.miniWrapperExpanded {
  transition: none;
  transform: translate(0, #{mini.$height + 16px});
  visibility: hidden;
}

.maxiWrapper {
  z-index: zIndex.$player;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  touch-action: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(0, 100vh);
  transition: transform 0.1s ease-in, visibility 0.1s;
  visibility: hidden;
  @media (min-width: breakpoint.$md) {
    top: auto;
    left: auto;
    right: 16px;
    bottom: 16px;
    width: 375px;
    max-height: calc(100vh - 16px - 16px - 54px);
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
  }
}

.maxiWrapperExpanded {
  transform: translate(0, 0);
  transition: transform 0.3s cubic-bezier(0, 0, 0.1, 1);
  visibility: visible;
}
