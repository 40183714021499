$search-input: 4;
$search-suggestions: 5;
$player: 2000;
$chat: 1999;
$site-header-menu: 2001;
$cookie-bar: 2002;
$site-header-search-header: 2002;
$more-menu: 2003;
$search-filter: 2003;
$search-loader: 2003;
$chat: 2003;
$modal: 2004;
$market-selector: 2004;
$alert: 2005;
