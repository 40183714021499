@use 'src/styles/breakpoint';
@use 'src/styles/fontweight';
@use 'src/styles/color';

.container {
  position: relative;
  list-style: none;

  :global(.desktop-variant) & {
    margin: 0 -16px;
  }

  & > * {
    display: flex;
    width: 100%;
    cursor: pointer;
    color: color.$white;
    font-size: 16px;
    outline-offset: -4px;
    padding: 11px 24px;
    font-weight: fontweight.$semi_bold;

    svg {
      margin-top: 2px;
      flex-shrink: 0;
      fill: color.$white;
      margin-right: 8px;
    }
  }

  :global(.desktop-variant) & > * {
    color: color.$black;
    padding: 11px 16px;
    font-weight: fontweight.$medium;

    svg {
      fill: color.$black;
    }
  }

  @media (hover: hover) {
    & > *:hover {
      background-color: rgba(color.$white, 0.08);
    }
    & > *:active {
      background-color: rgba(color.$white, 0.06);
    }

    :global(.desktop-variant) & > *:hover {
      background-color: rgba(color.$black, 0.08);
    }
    :global(.desktop-variant) & > *:active {
      background-color: rgba(color.$black, 0.06);
    }
  }
}

.containerDisabled {
  opacity: 0.7;
  pointer-events: none;
}

.button {
  text-align: left;
  background-color: transparent;
  border: none;
}
