@use 'src/styles/color';

.fallback{
  height: 100%;
  width: 100%;
  position: relative;
}

.fallbackBackgroundImage {
  width: 100%;
  height: 100%;
}
.icon {
  width: 35%;
  height: 35%;
  opacity: .5;
}

.fallbackTitle {
  line-height: 1.3;
  font-weight: bold;
  display: block;
  position: relative;
  text-align: center;
  color: color.$white;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fallbackTitleSmall {
  max-width: calc(100% - 8px);
  font-size: 10px;
}

.fallbackTitleMedium {
  max-width: calc(100% - 16px);
  font-size: 12px;
}

.fallbackTitleLarge {
  max-width: calc(100% - 20px);
  font-size: 16px;
}

.fallbackContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
