@use 'src/styles/breakpoint';
@use "src/styles/color";

.status {
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  background: #333;
  font-size: 13px;
  white-space: nowrap;
  padding: 0 20px;
  @media (min-width: breakpoint.$md) {
    padding: 0 60px;
  }
}

.link {
  color: color.$white;
  border-bottom: 1px dashed color.$grey-40;
  margin-left: 4px;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.degraded {
  padding-bottom: 1px;
  color: orange;
}

.downtime {
  white-space: nowrap;
  padding-bottom: 1px;
  color: darkorange;
}

.closeButton {
  flex-shrink: 0;
  display: flex;
  padding: 0;
  background: none;
  border: 0;
  outline: none;
  color: color.$white;
  fill: color.$grey-20;
  cursor: pointer;
  margin-left: auto;
}
