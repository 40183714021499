@use "src/styles/color";

.container {
  display: flex;
  align-items: center;
  width: 100%;
}

.timeButton {
  display: flex;
  flex-shrink: 0;
  padding: 0;
  border-color: transparent;
  background: transparent;
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
}

.time {
  color: color.$white;
  font-size: 12px;
  opacity: 0.75;
  font-variant-numeric: tabular-nums;
}

$progressBar: color.$accent-100;
$thumbcolor: color.$white;
$foreground: rgba(255, 255, 255, 0.1);

@mixin thumb {
  // Scale up to increase hit area (16 * 2 = 32).
  transform: scale(2, 2);
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: $thumbcolor;
  // Don't show background under transparent border.
  background-clip: padding-box;
  // Use transparent border to increase hit area but keeping original visible
  // thumb size. 4 * 2 (border) removes 8 from the background. Then we scale up
  // everything * 2 to make the visible 8 size 16 again.
  // Change transparent border color to see what going on when developing.
  border: 4px solid transparent;
  opacity: 1;
  box-shadow: 0 2px 6px #00000011;
  border-radius: 50%;
  margin-top: 0;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  &:active {
    transform: scale(2.2, 2.2);
  }
}

.range {
  position: absolute;
  top: 0;
  appearance: none;
  height: 18px;
  width: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
  touch-action: none;

  &:disabled {
    cursor: not-allowed;
  }

  // Webkit - Google Chrome, Safari, Android Browser.
  &::-webkit-slider-thumb {
    @include thumb
  }
  &:disabled::-webkit-slider-thumb {
    // NOTICE: The thumb is half transparent on iOS 15.5 when the range input
    // is disabled. It seems like the value can't be overridden manually here.
    cursor: not-allowed;
  }

  // Moz - Firefox.
  &::-moz-range-thumb {
    @include thumb
  }
  &:disabled::-moz-range-thumb {
    cursor: not-allowed;
  }

  // MS - Internet Explorer, Edge.
  &::-ms-thumb {
    @include thumb
  }
  &:disabled::-ms-thumb {
    cursor: not-allowed;
  }
}

.wrapper {
  position: relative;
  flex: 1;
  height: 18px;
  margin: 0 8px;
}

.progressBar {
  position: absolute;
  top: 0;
  bottom: 0;
  background: $progressBar;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;
}

.progressTrack {
  position: absolute;
  top: 6px;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  overflow: hidden;
}

.progressTrack::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $foreground;
  content: "";
}
