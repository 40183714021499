@use 'src/styles/breakpoint';
@use 'src/styles/color';
@use './variables' as mini;

.container {
  position: relative;
  padding: 8px 8px #{8px + mini.$progressHeight};
  background: #808080;
  transition: background 1s ease-out;
  @media (min-width: breakpoint.$md) {
    border-radius: 5px;
    overflow: hidden;
  }
}

.colorFade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(108deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 1) 100%);
}

.border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border-top: 1px rgba(255, 255, 255, 0.1) solid;
  // Border right needs to be slightly less transparent to be visible.
  border-right: 1px rgba(255, 255, 255, 0.15) solid;
  // NOTICE: No border bottom because we got the progress line in the mini player.
  border-left: 1px rgba(255, 255, 255, 0.1) solid;
  clip-path: inset(0 0 mini.$progressHeight 0);
  display: none;
  @media (min-width: breakpoint.$md) {
    display: block;
  }
}

.itemsWrapper {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
}

.expandButton {
  background: transparent;
  cursor: pointer;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  overflow: hidden;
  margin-right: 6px;
}

.image {
  margin-right: 6px;
  width: 44px;
  height: 44px;
  background-color: rgba(color.$white, 0.1);
  flex-shrink: 0;
  border-radius: 2px;
  overflow: hidden;
  fill: rgba(255, 255, 255, 0.5);
}

.title {
  color: color.$white;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 13px;
  line-height: 1.2;
}

.liveWrapper {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.onAir {
  display: inline-block;
  color: color.$white;
  font-size: 8px;
  padding: 2px 6px;
  text-transform: uppercase;
  background-color: color.$accent-100;
  border-radius: 70px;
}

.liveTitle {
  width: 100%;
  color: color.$white;
  margin-right: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  white-space: nowrap;
}

@mixin actionButton {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  border-color: transparent;
  background: transparent;
  cursor: pointer;
  fill: #ffffff;
  transition: transform 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.1, 1.1);
  }
  &:active {
    transition: transform 0.1s ease-out;
    transform: scale(0.95, 0.95);
  }
}

.chatButton {
  @include actionButton;
}

.playPauseButton {
  @include actionButton;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  animation: spin 1s linear infinite;
}

.progressWrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: #{mini.$progressHeight};
  // Liner gradient will make the progress bar background match left and right
  // border color (they have different opacity).
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.15));
}

.progressFill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 75%;
  background: #FF5B65;
}

.errorMessage {
  min-height: mini.$height;
  @media (min-width: breakpoint.$md) {
    height: mini.$height;
    min-height: 0;
    border-radius: 5px;
  }
}
