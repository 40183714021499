@use 'fontweight';
@use 'breakpoint';
@use 'src/components/Player/components/Mini/variables' as player;
@use 'color';

body,
html {
  height: 100%;
  // This will be shown on scroll bounce. Header and footer are back so make
  // this black as well.
  background-color: color.$black;
}

#__next {
  min-height: 100vh;
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: fontweight.$medium;
  line-height: 1.5;
  color: color.$grey-90;
  text-align: left;
}

*,
:after,
:before {
  box-sizing: border-box;
}

#__next {
  display: flex;
  flex-direction: column;
  // Make room for player in the bottom (take height for desktop variant which
  // has both height and spacing).
  padding-bottom: #{player.$height + 16px};
}

main {
  // HTML/body color is set to black to have a nice scroll bounce color. Set
  // color to white here to make all main content work as expected.
  background-color: color.$white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: fontweight.$semi_bold;
  margin-bottom: 1.875rem;
  line-height: 1.4;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: color.$black;
  text-decoration: none;
  background-color: transparent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

img,
svg {
  vertical-align: middle;
}

img {
  border-style: none;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace;
}

code {
  font-size: 87.5%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
