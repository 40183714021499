@use "src/styles/color";

.container {
  display: flex;
  align-items: center;
  width: 100%;
}

.itemButton {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  margin-right: 8px;
  text-align: left;
}

.image {
  margin-right: 8px;
  width: 40px;
  height: 40px;
  background-color: rgba(color.$white, 0.1);
  flex-shrink: 0;
  border-radius: 1px;
  overflow: hidden;
  fill: rgba(255, 255, 255, 0.5);
}

.infoWrapper {
  min-width: 0;
}

.title {
  min-width: 0;
  color: color.$white;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  white-space: nowrap;
}

.dateAndDurationWrapper {
  display: flex;
  color: rgba(color.$white, 0.5);
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  & *:not(:last-child):after {
    content: "•";
    margin: 0 4px;
  }
}

.menuButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #ffffff;
  background-color: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
  margin-left: auto;
}
