@use "src/styles/color";

.container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 4px;
  width: 100%;
  padding: 15px 11px 22px;
}

.button {
  min-width: 0;
  font-size: 12px;
  color: color.$white;
  fill: color.$white;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: transparent;
  background: transparent;
  cursor: pointer;
  padding: 1px 4px;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }

  &:active {
    transition: opacity 0.1s ease-out;
    opacity: 0.75;
  }

  &:disabled {
    opacity: 0.25;
    cursor: auto;
  }
}

.text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Highlight button when active or not in "normal state".
.buttonActive {
  fill: color.$accent-100;
  color: color.$accent-100;
}

.checkIcon {
  margin-left: 8px;
  fill: color.$accent-100 !important;
}
