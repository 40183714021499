@use "src/styles/color";

.container {
  position: relative;
  padding: 0 24px 24px;
  // Give container minimum height to make the animation from last queue item
  // to "no queue" message work. This height should cover the heading and one
  // queue item or the "no queue" message height.
  min-height: 124px;
}

.heading {
  font-size: 16px;
  color: color.$white;
}

.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.item {
  // NOTICE: Use hardcoded "max-height" to be able to animate out item. CSS
  // transition can't go from height auto to number. Padding included in
  // max-height (12 + 40 + 12  = 64).
  max-height: 64px;
  margin: 0 -24px;
  padding: 12px 24px;
  &:last-child {
    margin-bottom: 0;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba(color.$white, 0.08);
    }
    &:active {
      background-color: rgba(color.$white, 0.06);
    }
  }
}

.itemEnter {
  opacity: 0;
  max-height: 0;
  padding: 0 24px;
}

.itemEnterActive {
  transition: max-height 300ms ease-out 150ms, padding 300ms ease-out 150ms, opacity 300ms ease-out 450ms;
  max-height: 64px;
  padding: 12px 24px;
  opacity: 1;
}

.itemExitActive {
  transition: opacity 150ms ease-out, max-height 300ms ease-out 150ms, padding 300ms ease-out 150ms;
  pointer-events: none;
  opacity: 0;
  max-height: 0;
  padding: 0 24px;
}

@keyframes animateIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.noQueueWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 0;
  // Position absolute to avoid taking space - This will make the transition
  // from last queue item to showing this message work (we don't want the
  // expanded desktop dynamic height to jump back and forth).
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // Animate in message to make it appear after potential queue item has
  // animated out.
  animation: animateIn 500ms ease-out 500ms backwards;
}

.noQueue {
  margin: 0;
  color: rgba(color.$white, 0.5);
  font-size: 14px;
  text-align: center;
}
