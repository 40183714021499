@use 'src/styles/color';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 30px;
  background: #F2DCDC;
}

.message {
  color: color.$warning-100;
  padding: 0;
  text-align: center;
  border-color: transparent;
  background: transparent;
  display: flex;
  cursor: pointer;
  transition: color 0.2s ease-out;
  text-decoration: underline;
  &:hover {
    color: color.$warning-100;
  }
  &:active {
    color: color.$warning-100;
  }
}

.closeButton {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 2px;
  fill: #A71212;
  border-radius: 2px;
  border-color: transparent;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: rgba(167, 18, 18, 0.1);
  }
  &:active {
    background-color: rgba(167, 18, 18, 0.2);
  }
}
