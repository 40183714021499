@use 'src/styles/breakpoint';
@use 'src/styles/color';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #808080;
  transition: background 1s ease-out;
  @media (min-width: breakpoint.$md) {
    height: auto;
    border-radius: 5px;
    overflow: hidden;
  }
}

.colorFade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%,  rgba(0, 0, 0, 1) 100%);
}

.border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border-top: 1px rgba(255, 255, 255, 0.1) solid;
  border-right: 1px rgba(255, 255, 255, 0.1) solid;
  // Border bottom needs to be slightly less transparent to be visible.
  border-bottom: 1px rgba(255, 255, 255, 0.15) solid;
  border-left: 1px rgba(255, 255, 255, 0.1) solid;
  display: none;
  @media (min-width: breakpoint.$md) {
    display: block;
  }
}

.queue {
  position: relative;
  overflow: auto;
  overscroll-behavior: contain;
  flex-shrink: 1;
  flex-grow: 1;
  height: auto;
  mask-image: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 1) 2px, rgba(0, 0, 0, 1) calc(100% - 24px), transparent 100%);
  &::-webkit-scrollbar {
    display: none;
  }
}

.closeButton {
  position: absolute;
  right: 17px;
  top: 24px;
  padding: 2px;
  fill: #FFFFFF;
  border-radius: 50%;
  border-color: transparent;
  background: transparent;
  cursor: pointer;
  display: flex;
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: rgba(color.$white, 0.25);
  }
  &:active {
    background-color: rgba(color.$white, 0.15);
  }
  svg {
    rotate: 90deg;
  }
}

.playerWrapper {
  position: relative;
  flex-shrink: 0;
  margin-top: auto;
}

.divider {
  position: relative;
  margin: 0 12px;
  border: none;
  height: 1px;
  background-color: rgba(color.$white, 0.1);
}

.errorMessage {
  margin-top: auto;
  flex-shrink: 0;
  // Height should match player controls and bottom menu.
  height: 187px;
}
