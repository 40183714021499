@use 'src/styles/color';

.container {
  position: relative;
  padding: 24px;
}

.heading {
  font-size: 16px;
  color: color.$white;
  // Margin right is needed to leave room for maxi close button.
  margin-right: 32px;
  margin-bottom: 12px;
}

.itemsWrapper {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
}

.image {
  margin-right: 8px;
  width: 59px;
  height: 59px;
  background-color: rgba(color.$white, 0.1);
  flex-shrink: 0;
  border-radius: 2px;
  overflow: hidden;
  fill: rgba(255, 255, 255, 0.5);
  &:hover {
    filter: brightness(105%);
  }
  &:active {
    filter: brightness(95%);
  }
}

.textWrapper {
  overflow: hidden;
  // Some flex props to make it look the same as "ActiveItem".
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.onAir {
  display: inline-block;
  color: color.$white;
  font-size: 8px;
  padding: 2px 6px;
  text-transform: uppercase;
  background-color: color.$accent-100;
  border-radius: 70px;
  margin-bottom: 2px;
}

.title {
  color: color.$white;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 1.2;
}
